body {
  color: #555843;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #555843;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #125a05;
}

.login-form-container {
  width: 100%;
  max-width: 500px;
  padding: 50px;
  margin: 10%;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
}
td {
  word-wrap: break-word;
  padding: 0;
}
.ant-btn-submit {
  color: #ffffff;
  background-color: #555843;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-submit :hover {
  color: #ffffff !important;
  background-color: #555843 !important;
}
.ant-btn-print {
  color: #ffffff;
  background-color: #125a05;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-print :hover {
  color: #ffffff !important;
  background-color: #125a05 !important;
}
.ant-btn-invoice {
  color: #ffffff;
  background-color: #f1a60f;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-btn-invoice :hover {
  color: #ffffff !important;
  background-color: #f1a60f !important;
}
.ant-menu-submenu-title {
  color: #ffffff !important;
}
.ant-menu-item-selected {
  background-color: #555843 !important;
  color: #ffffff !important;
}
.ant-menu-submenu-title {
  color: #555843 !important;
}
.row-padding-10 {
  padding: 10px;
}
.ant-breadcrumb-link {
  color: #125a05 !important;
}
.ant-card {
  border: 1px solid #125a05;
}
.ant-card .ant-card-head {
  background-color: #125a05;
  color: #ffffff;
}
:where(.css-dev-only-do-not-override-17a39f8).ant-switch.ant-switch-checked {
  background: #125a05;
}
.primary-color {
  background-color: #125a05;
  color: #ffffff;
}
.read-only {
  background-color: #125a05;
  color: #ffffff;
}
.red-row {
  background-color: #800020;
}
.text-danger {
  color: #800020;
}
